<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">
        <a
          href="https://docs.google.com/document/d/e/2PACX-1vQmipIPg4EQxCFPEEMTNQ9bgg1tRciAEHbEe4M3IsN7mF65BGg7xvZ9ytgj-mFmtz2LUWJEZyaUsNNi/pub"
          >Error</a
        >
        is part of making experimental measurements. Match the following experimental issues to a
        type of error.
      </p>
      <p class="mb-2">
        <a
          href="https://docs.google.com/document/d/e/2PACX-1vQmipIPg4EQxCFPEEMTNQ9bgg1tRciAEHbEe4M3IsN7mF65BGg7xvZ9ytgj-mFmtz2LUWJEZyaUsNNi/pub"
          >Click here for more info about error analysis</a
        >
      </p>

      <p class="mb-2" v-for="letter in letterOptions" :key="letter.inputName">
        {{ letter.text }}
        <v-select
          v-model="inputs[letter.inputName]"
          style="display: inline-block; max-width: 300px"
          class="my-1"
          :items="options"
          item-text="text"
          item-value="value"
          label="Select an option:"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import STextarea from '@/common/components/STextarea.vue';

export default {
  name: 'ChemUCI1LC_Q4S2_Q4',
  components: {StembleLatex, STextarea},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
      },
      options: [
        {
          text: 'Failure to read the last significant figure (the uncertain number between the markings) from a buret',
          value: 'option1',
        },
        {text: 'Inaccurate calibration of the pH probe', value: 'option2'},
        {
          text: 'Consistently reading the buret volume by looking up at the liquid level',
          value: 'option3',
        },
        {text: 'Reduced by using a best fit line on a calibration curve', value: 'option4'},
        {text: 'Result of equipment failure', value: 'option5'},
      ],
      letterOptions: [
        {text: 'Random Error: ', inputName: 'input1'},
        {text: 'Instrumental Error: ', inputName: 'input2'},
        {text: 'Personal Error: ', inputName: 'input3'},
        {text: 'Gross Error: ', inputName: 'input4'},
        {text: 'Methodological Error: ', inputName: 'input5'},
      ],
    };
  },
};
</script>
